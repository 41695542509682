import moment from "moment";
import { DateTime } from "types";
import { DashboardTimePeriod } from "types/Dashboard";

class TimeHelper {
  static getDiff(startTime?: DateTime, endTime?: DateTime) {
    if (!(startTime && endTime)) return null;

    const start = moment(startTime);
    const end = moment(endTime);
    const diff = moment.utc(end).diff(moment.utc(start));

    /**
     * @note
     * `HH:mm` is 24 hour time
     * `hh:mm` is 12 hour time
     */

    return moment.utc(diff).format("HH:mm");
  }

  static getRelativePeriodDates(
    relativePeriod: DashboardTimePeriod["relativePeriod"],
    params?: { endDate?: DateTime }
  ) {
    const duration = moment.duration(relativePeriod);

    if (!["P1D", "P7D", "P30D", "P90D", "P365D"].includes(`${relativePeriod}`)) {
      return null;
    }

    // Today end of Day
    const end = moment(params?.endDate).endOf("day");
    // Subtract duration from end of day
    const start = end.clone().subtract(duration, "day").startOf("day");

    return {
      start: start.toISOString(),
      end: end.toISOString(),
    };
  }
}

export default TimeHelper;
