import { useCallback, useMemo } from "react";
import MetadataStoreContext from "./contexts/MetadataStoreContext";
import MetadataActionsContext from "./contexts/MetadataActionsContext";
import useExercises from "providers/ExercisesProvider/useExercises";
import { MetadataType } from "types";
import type { MetadataAnthropometric, Metadata, MetadataExercise, MetadataWellness } from "types";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";
import useAnthropometricMetadata from "providers/AnthropometricMetadataProvider/useAnthropometricMetadata";
import MetadataHelpers from "helpers/MetadataHelpers";

interface MetadataProviderProps {
  children?: React.ReactNode;
}

function MetadataProvider(props: MetadataProviderProps) {
  const anthropometrics = useAnthropometricMetadata();
  const wellness = useWellnessMetadata();
  const exercises = useExercises();

  const metadataFlatten = useMemo<Metadata[]>(() => {
    const exercisesWithType: MetadataExercise[] = exercises.map((elem) => ({
      id: elem.id,
      metadata: elem,
      type: MetadataType.EXERCISE,
    }));

    const wellnessWithType: MetadataWellness[] = wellness.map((elem) => ({
      id: elem.id,
      metadata: elem,
      type: MetadataType.WELLNESS,
    }));

    const anthropometricsWithType: MetadataAnthropometric[] = anthropometrics.map((elem) => ({
      id: elem.value,
      metadata: elem,
      type: MetadataType.ANTHROPOMETRIC,
    }));

    /**
     * The order will be the order of the metadata in the UI.
     * We can change the order by changing the order of the array or sorting it.
     */
    return [...anthropometricsWithType, ...exercisesWithType, ...wellnessWithType];
  }, [anthropometrics, exercises, wellness]);

  const metadataMap = useMemo(() => {
    return metadataFlatten.reduce((acc, item) => ({ ...acc, [item.id]: item }), {} as Record<string, Metadata>);
  }, [metadataFlatten]);

  const getById = useCallback((id: string) => metadataMap[id] || null, [metadataMap]);

  const getMeasureName = useCallback(
    (id: string | null) => {
      if (!id) return null;

      const attribute = getById(id);
      return MetadataHelpers.getMetadataName(attribute);
    },
    [getById]
  );

  const getMetricLabel = useCallback(
    (id: string | null, field: string | null) => {
      if (!id) return null;

      const attribute = getById(id);
      return MetadataHelpers.getMetadataMetricLabel(attribute, field);
    },
    [getById]
  );

  const getMetric = useCallback(
    (id: string | null, field: string | null) => {
      if (!id) return null;

      const attribute = getById(id);
      return MetadataHelpers.getMetadataMetricByField(attribute, field);
    },
    [getById]
  );

  const checkHasVariants = useCallback(
    (id: string | null) => {
      if (!id) return false;

      const attribute = getById(id);
      return MetadataHelpers.hasVariants(attribute);
    },
    [getById]
  );

  const actions = useMemo(
    () => ({ getById, getMeasureName, getMetricLabel, getMetric, checkHasVariants }),
    [getById, getMeasureName, getMetricLabel, getMetric, checkHasVariants]
  );

  return (
    <MetadataActionsContext.Provider value={actions}>
      <MetadataStoreContext.Provider value={metadataFlatten}>{props.children}</MetadataStoreContext.Provider>
    </MetadataActionsContext.Provider>
  );
}

export default MetadataProvider;
