import React, { useEffect } from "react";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import Constants from "../../shared/utils/Constants";
import outputLogoFull from "../../assets/images/Logo.svg";
import outputLogoStripes from "./outputLogoStripes.svg";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import DrawerMenuLink from "./DrawerMenuLink";
import { useUserContext } from "hooks";
import * as Components from "./DrawerMenu.components";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { useDrawerMenuItems } from "components/App/App.config";
import RemoteAnnouncementBanner from "components/RemoteAnnouncementBanner";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";
import useRole from "providers/RoleProvider/useRole";
import { OrganisationRole } from "types/organisation";
import CoachHelpers from "helpers/CoachHelpers";
import { useMediaQuery, useTheme } from "@mui/material";

function DrawerMenu(props) {
  const menuItems = useDrawerMenuItems();
  const [open, setOpen] = React.useState(LocalStorageHelper.get(LocalStorageHelper.keys.drawerMenuOpen, false));
  const location = useLocation();
  const [{ profile }] = useUserContext();
  const organisation = useOrganisation();
  const role = useRole();

  const toggleDrawer = () => {
    setOpen((open) => {
      LocalStorageHelper.set(LocalStorageHelper.keys.drawerMenuOpen, !open);
      return !open;
    });
  };

  const makeIcon = (icon, isNew) => {
    if (!isNew) return icon;

    return (
      <Components.NewIndicatorBadge
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        variant="dot"
      >
        {icon}
      </Components.NewIndicatorBadge>
    );
  };

  const photoUrl = useMakeProfileImageURL(
    role !== OrganisationRole.COACH ? profile?.profilePhotoName : CoachHelpers.getProfileImagePath(profile)
  );

  const theme = useTheme();
  const alwaysClosed = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    /**
     * @author {Carden}
     * Set drawer to closed on small screens */
    if (alwaysClosed) setOpen(false);
  }, [alwaysClosed]);

  return (
    <Box display="flex">
      <RemoteAnnouncementBanner />
      <CssBaseline />
      <Components.Drawer open={open} variant="permanent">
        <List>
          <ListItem>
            <Link to={Constants.routes.home.route}>
              <Components.LogoContainerStyledBox mt={2} mb={2.5} pl={0.5} display="block">
                <Components.Logo src={outputLogoFull} alt="Output Sports logo" show={open} pl={1} width={192} />
                <Components.Logo src={outputLogoStripes} alt="Output Sports stripes" show={!open} ml="-3px" />
              </Components.LogoContainerStyledBox>
            </Link>
          </ListItem>
          {menuItems.map((item, index) => (
            <DrawerMenuLink
              selected={location.pathname === item.route}
              key={item.title}
              to={item.route}
              icon={
                <Box pl={1.5} pt={0.5}>
                  {makeIcon(item.icon, item.isNew)}
                </Box>
              }
              primary={
                <Typography variant="h3" gutterBottom={false}>
                  <Box fontWeight="fontWeightLight">{item.title}</Box>
                </Typography>
              }
              secondary={
                item.isNew ? (
                  <Typography component="span" gutterBottom={false}>
                    <Box component="span" color="secondary.main">
                      New
                    </Box>
                  </Typography>
                ) : undefined
              }
            />
          ))}
        </List>
        <Box flex={1} />
        <Box p={2}>
          <Grid container alignItems="center" spacing="8px" wrap="nowrap">
            <Grid item>
              <Components.LinkAvatar to={Constants.routes.settings.route} photoUrl={photoUrl} />
            </Grid>
            <Grid item xs title={organisation.name} zeroMinWidth>
              <Typography variant="h3" noWrap pl={1.5} maxWidth="220px">
                {profile.name}
              </Typography>
              <Typography noWrap pl={1.5} maxWidth="220px">
                {organisation.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing="8px">
            <Grid item xs={12}>
              <Components.DrawerOpenIconButton
                onClick={toggleDrawer}
                disabled={alwaysClosed}
                sx={alwaysClosed ? { opacity: 0.3 } : undefined}
              >
                {open ? <ChevronLeftIcon htmlColor="white" /> : <ChevronRightIcon htmlColor="white" />}
              </Components.DrawerOpenIconButton>
            </Grid>
          </Grid>
        </Box>
      </Components.Drawer>
      <Components.Content>{props.children}</Components.Content>
    </Box>
  );
}

export default DrawerMenu;
