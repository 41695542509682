import { useEffect, useMemo, useState } from "react";
import DashboardActionsContext from "./contexts/DashboardActionsContext";
import DashboardService from "pages/Dashboards/services/DashboardService";
import type { Status } from "types/Status";
import type { Dashboard, DashboardTile } from "types/Dashboard";
import MetadataProvider from "providers/MetadataProvider";
import DashboardApiStatusContext from "pages/Dashboards/contexts/DashboardApiStatusContext";
import { useMatch } from "react-router-dom";
import useDashboardsReducer from "./useDashboardsReducer";
import { DashboardActionType } from "./useDashboardsReducer/useDashboardsReducer";
import DashboardStoreContext from "./contexts/DashboardStoreContext";
import useDashboardAutosaveEffect from "./useDashboardAutosaveEffect";
import { DashboardActions } from "./DashboardProvider.types";
import usePreferences from "hooks/usePreferences";
import DashboardNoDataWrapper from "./DashboardNoDataWrapper";
import TargetDashboardContext from "./contexts/TargetDashboardContext";
import OpenAddWidgetDialogProvider from "pages/Dashboards/DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddAthleteWidgetDialog/OpenAddWidgetDialogProvider";

interface DashboardProviderProps {
  children?: React.ReactNode;
}

function DashboardProvider(props: DashboardProviderProps) {
  const [allDashboards, dispatchDashboards] = useDashboardsReducer();
  const [{ defaultDashboardId }] = usePreferences();

  const match = useMatch("/dashboards/:dashboardId");
  const [status, setStatus] = useState<Status>("fetching");

  const dashboard = useMemo<Dashboard | null>(() => {
    if (match?.params.dashboardId) {
      return allDashboards.find((dashboard) => dashboard.id === match?.params.dashboardId) || null;
    }
    return allDashboards.find((dashboard) => dashboard.id === defaultDashboardId) || allDashboards[0] || null;
  }, [allDashboards, defaultDashboardId, match?.params.dashboardId]);

  useDashboardAutosaveEffect(dashboard, dispatchDashboards);

  // Memoized actions
  const actions: DashboardActions = useMemo(() => {
    const getById: DashboardActions["get"] = async (id: string) => {
      const data = await DashboardService.get(id);
      dispatchDashboards({ type: DashboardActionType.ADD_ONE, payload: data });
      return data;
    };

    return {
      getAll: async () => {
        const data = await DashboardService.getAll();
        dispatchDashboards({ type: DashboardActionType.ADD_ALL, payload: data });
        return data;
      },
      get: getById,
      clearAll: () => {
        dispatchDashboards({ type: DashboardActionType.CLEAR_ALL });
      },
      addOne: async (newDashboard) => {
        const { id } = await DashboardService.create(newDashboard);
        const dashboard = await getById(id);
        return dashboard;
      },
      updateOne: (dashboard: Partial<Dashboard> & Pick<Dashboard, "id">) => {
        dispatchDashboards({ type: DashboardActionType.UPDATE_ONE, payload: dashboard });
      },
      updateView: async (dashboard: Pick<Dashboard, "id" | "isGlobal">) => {
        dispatchDashboards({ type: DashboardActionType.UPDATE_VIEW, payload: dashboard });
        return DashboardService.updateView(dashboard);
      },
      deleteOne: async (dashboard: Pick<Dashboard, "id">) => {
        await DashboardService.delete(dashboard);
        dispatchDashboards({ type: DashboardActionType.DELETE_ONE, payload: dashboard });
        return dashboard;
      },
      addTile: (dashboardId: string, tile: DashboardTile) => {
        dispatchDashboards({ type: DashboardActionType.ADD_TILE, payload: tile, dashboardId });
      },
      updateTile: (dashboardId: string, tile: Partial<DashboardTile> & Pick<DashboardTile, "id">) => {
        dispatchDashboards({ type: DashboardActionType.UPDATE_TILE, payload: tile, dashboardId });
      },
      deleteTile: (dashboardId: string, tile: Pick<DashboardTile, "id">) => {
        dispatchDashboards({ type: DashboardActionType.DELETE_TILE, payload: tile, dashboardId });
      },
    };
  }, [dispatchDashboards]);

  // API Fetch
  useEffect(() => {
    (async () => {
      try {
        await actions.getAll();
        setStatus("success");
      } catch (error) {
        setStatus("error");
      }
    })();
  }, [actions]);

  return (
    <DashboardNoDataWrapper fetching={status === "fetching"} dashboards={allDashboards}>
      <MetadataProvider>
        <DashboardApiStatusContext.Provider value={status}>
          <DashboardActionsContext.Provider value={actions}>
            <DashboardStoreContext.Provider value={allDashboards}>
              <TargetDashboardContext.Provider value={dashboard}>
                <OpenAddWidgetDialogProvider>{props.children}</OpenAddWidgetDialogProvider>
              </TargetDashboardContext.Provider>
            </DashboardStoreContext.Provider>
          </DashboardActionsContext.Provider>
        </DashboardApiStatusContext.Provider>
      </MetadataProvider>
    </DashboardNoDataWrapper>
  );
}

export default DashboardProvider;
