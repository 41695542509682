import http from "axiosSetup";
import { Dashboard } from "types/Dashboard";
import { DashboardActions } from "pages/Dashboards/providers/DashboardProvider";
class DashboardService {
  static delete: DashboardActions["deleteOne"] = async (dashboard): Promise<{ id: string }> => {
    return http.delete<{ id: string }>(`/dashboards/${dashboard.id}`).then((response) => response.data);
  };

  static create: DashboardActions["addOne"] = async (newDashboard): Promise<{ id: string }> => {
    return http.post<{ id: string }>(`/dashboards`, newDashboard).then((response) => response.data);
  };

  static async save(dashboard: Dashboard): Promise<{ id: string }> {
    return http
      .put<{ id: string }>(`/dashboards/${dashboard.id}`, JSON.parse(JSON.stringify(dashboard)))
      .then((response) => response.data);
  }

  static async updateView(dashboard: Pick<Dashboard, "id" | "isGlobal">): Promise<void> {
    return http
      .patch(`/dashboards/${dashboard.id}/global`, { isGlobal: dashboard.isGlobal })
      .then((response) => response.data);
  }

  static async getAll(): Promise<Dashboard[]> {
    const result = await http.get<Dashboard[]>("/dashboards").then((response) => response.data);
    return result;
  }

  static async get(id: string): Promise<Dashboard> {
    const result = await http.get<Dashboard>(`/dashboards/${id}`).then((response) => response.data);
    return result;
  }
}

export default DashboardService;
