import DashboardsHome from "./DashboardsHome/DashboardsHome.translations-en.json";
import DashboardFilters from "pages/Dashboards/DashboardsHome/DashboardFilters/DashboardFilters.translations-en.json";
import DashboardSelectorsPermissionsToggle from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsPermissionsToggle/DashboardSelectorsPermissionsToggle.translations-en.json";
import DashboardSelectorsMoreMenu from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardSelectorsMoreMenu.translations-en.json";
import DashboardConfirmDeleteDialog from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardConfirmDeleteDialog/DashboardConfirmDeleteDialog.translations-en.json";
import DashboardNoDataWrapper from "./providers/DashboardProvider/DashboardNoDataWrapper/DashboardNoDataWrapper.translations-en.json";
import DashboardEmptyWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/DashboardEmptyWidget.translations-en.json";
import AddAthleteWidgetDialog from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddAthleteWidgetDialog/AddAthleteWidgetDialog.translations-en.json";
import AthleteProgressChartWidget from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/AthleteProgressChartWidget.translations-en.json";
import Widgets from "./DashboardsHome/DashboardWidgetFactory/Widgets/Widgets.translations-en.json";
import AddAthleteWidgetSelectWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddAthleteWidgetDialog/AddAthleteWidgetSelectWidget/AddAthleteWidgetSelectWidget.translations-en.json";
import DashboardDuplicateToEditBar from "./DashboardsHome/DashboardDuplicateToEditBar/DashboardDuplicateToEditBar.translations-en.json";
import TooltipProgressTitle from "./DashboardsHome/DashboardWidgetFactory/components/Tooltips/TooltipProgressTitle.translations-en.json";
import LegendPersonalBest from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/components/LegendPersonalBest/LegendPersonalBest.translations-en.json";
import AthleteGroupHorizontalBarChart from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteGroupHorizontalBarChart/AthleteGroupHorizontalBarChart.translations-en.json";
import LegendTarget from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/components/LegendTarget/LegendTarget.translations-en.json";
import AthleteGroupNumerical from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteGroupNumerical/AthleteGroupNumerical.translations-en.json";
import WidgetSelectionDescription from "./DashboardsHome/DashboardWidgetFactory/components/WidgetSelectionDescription.translations-en.json";

const DashboardsTranslationsEn = Object.assign(
  {
    Dashboards: {
      loadingMessage: "Loading Dashboards...",
    },
  },
  AthleteGroupNumerical,
  AddAthleteWidgetDialog,
  AddAthleteWidgetSelectWidget,
  AthleteProgressChartWidget,
  DashboardsHome,
  DashboardConfirmDeleteDialog,
  DashboardDuplicateToEditBar,
  DashboardFilters,
  DashboardEmptyWidget,
  DashboardNoDataWrapper,
  DashboardSelectorsMoreMenu,
  DashboardSelectorsPermissionsToggle,
  LegendPersonalBest,
  LegendTarget,
  TooltipProgressTitle,
  LegendPersonalBest,
  AthleteGroupHorizontalBarChart,
  Widgets,
  WidgetSelectionDescription,
  {
    DashboardEditPanels: {
      overrideHelperText: "This filter overrides the dashboard's time period",
      editPanelOverrideGroupHelperText: "This filter overrides the dashboard's group filter",
      resetOverrideHelperText: "Overriding dashboard filter. <2>Reset</2>",
      editFiltersLabel: "Edit filters",
    },
  }
);

export default DashboardsTranslationsEn;
